<template>
  <div>
    <div class="punch-card-btn">
      <div class="btn" @click="punchCardClick()">打卡</div>
    </div>
    <van-popup
      v-model="showNFCPopupInner"
      class="nfc-popup"
      position="bottom"
      closeable
      close-icon-position="top-left"
      @close="popupClose()"
    >
      <div class="title-box">打卡</div>
      <div class="nfc-tips-box">
        <img
          src="../../../assets/images/nfc-tips.png"
          alt="nfc-tips"
          width="256"
          height="256"
        />
        <p>请将手机靠近打卡点</p>
        <div v-if="signInvalid && showRfidCompensation" class="rfid-no-resp">
          打卡无响应？请<span class="click-here" @click="onClickResp"
            >点击此处</span
          >
        </div>
        <div v-if="showTips" class="rfid-no-resp">
          打卡无响应？请尝试重启NFC后重试
        </div>
      </div>
    </van-popup>
    <SelectPopupGrade
      ref="selectPopupGrade"
      v-model="pointResultId"
      :multi="false"
      :immediately="false"
      title="请选择巡检点"
      :list="pointList"
      :visible="pointListVisiable"
      list-key="value"
      @change="pointListChange"
      @close="pointListVisiable = false"
    />
  </div>
</template>

<script>
import { getPointResultList } from "@/api/psm/inspectionNew";
let tips5sTimer;
export default {
  name: "PunchCard",
  components: {},
  model: {
    prop: "",
    event: ""
  },
  props: {
    showNFCPopup: {
      type: Boolean,
      default: false
    },
    isSortSign: {
      type: Boolean,
      default: false
    },
    recordId: {
      type: String,
      default: ""
    },
    signInvalid: {
      type: Boolean,
      default: false
    }
    // value: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      pointResultId: "", // 补偿打卡的巡检点id
      pointListVisiable: false,
      pointList: [],

      showRfidCompensation: false,
      showNFCPopupInner: false,
      showTips: false
    };
  },
  watch: {
    showNFCPopup(newV) {
      this.showNFCPopupInner = newV;
      this.setTimeoutShow(newV);
      if (!newV) {
        this.showRfidCompensation = false;
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.showNFCPopupInner = this.showNFCPopup;
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    setTimeoutShow(isShow) {
      if (!isShow) return;
      tips5sTimer = setTimeout(() => {
        this.showTips = true;
        clearTimeout(tips5sTimer);
      }, 5000);
      this.timer = setTimeout(() => {
        this.showRfidCompensation = true;
        if (this.signInvalid) {
          this.showTips = false;
        }
        this.clearTime();
      }, 10000);
    },
    clearTime() {
      // 清空计时器
      if (this.timer) {
        console.log("结束计时");
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    // 选择补偿打卡点
    pointListChange(v) {
      if (v && v.length > 0) {
        const point = v[0];
        this.pointId = point.value;
        this.setCustomTakePhoto();
        const filePath = window.globalConfig.VUE_APP_FILE_URL + "/file/upload";
        // 调用自定义拍照
        window.androidH5.takePhoto2("getRfidPhoto", filePath);
      } else {
        this.pointId = "";
      }
    },
    setCustomTakePhoto() {
      window.getRfidPhoto = fileInfoStr => {
        // 抛出事件
        const fileInfoObj = JSON.parse(fileInfoStr);
        this.$emit("getFileInfo", fileInfoObj, this.pointId);
      };
    },
    onClickResp() {
      // 如果是非顺序打卡
      if (!this.isSortSign) {
        // 查询打卡点
        getPointResultList(this.recordId).then(res => {
          console.log("res", res);
          this.pointList = res;
          this.pointListVisiable = true;
        });
      } else {
        this.pointId = "";
        this.setCustomTakePhoto();
        const filePath = window.globalConfig.VUE_APP_FILE_URL + "/file/upload";
        // 调用自定义拍照
        window.androidH5.takePhoto2("getRfidPhoto", filePath);
      }
    },
    punchCardClick() {
      this.$emit("click");
    },
    popupClose() {
      this.clearTime();
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.punch-card-btn {
  position: fixed;
  bottom: 20px;
  // left: calc(50% - 32px);
  left: 50%;
  transform: translateX(-32px);
  .btn {
    width: 64px;
    height: 64px;
    line-height: 64px;
    text-align: center;
    background: #1271ff;
    border-radius: 50%;
    box-shadow: 0px 5px 12px 0px rgba(30, 120, 255, 0.6);
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
  }
}
.nfc-popup {
  height: 100vh;
  .title-box {
    height: 44px;
    line-height: 44px;
    padding: 6px 0 0;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #2e2e4d;
  }
  .nfc-tips-box {
    text-align: center;
    // padding: 61px 61px 0 58px;
    padding: 61px 0 0;
    p {
      padding: 65px 0 0;
      font-size: 18px;
      font-weight: 400;
      color: #2e2e4d;
    }
    .rfid-no-resp {
      display: inline-block;
      margin-top: 30px;
      font-size: 14px;
      color: #2e2e4d;
      background-color: rgba(255, 174, 19, 0.1);
      border-radius: 16px;
      line-height: 20px;
      padding: 6px 20px;
      span {
        text-decoration: underline;
        color: $--color-primary;
      }
    }
  }
}
</style>
